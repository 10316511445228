export default {
  primary: {
    padding: '0.75rem 1.5rem',
    margin: '0rem',
    border: 'solid 2px',
    borderColor: 'primary',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: '700',
    borderRadius: '10px',
    textDecoration: 'none',
    fontSize: '1rem',
    backgroundColor: 'primary',
    ':hover': {
      backgroundColor: 'secondary',
      color: 'white',
      borderColor: 'secondary'
    }
  },
  secondary: {
    padding: '1rem',
    borderRadius: '10px',
    border: 'solid 2px',
    borderColor: 'primary',
    backgroundColor: 'primary',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: '700',
    textDecoration: 'none',
    fontSize: '1rem',
    transition: 'all ease-in-out 0.5s',
    margin: '0rem',
    ':hover': {
      backgroundColor: 'secondaryDark',
      color: 'white'
    }
  }
}
