export default {
  text: '#202020',
  primary: '#0dcbff',
  primaryLight: '#b8f0ff',
  primaryDark: '#02537e',
  secondary: '#1daae0',
  secondaryLight: '#5ac3ec',
  secondaryDark: '#1976bc',
  secondaryDarker: '#175b8f',
  background: '#ffffff',
  backgroundSecondary: '#005a87',
  light: '#FFF',
  dark: '#010000'
}
